
import api from "@/api"

// 加入购物车
export function addShopCar( params ) {
  return api.post("/order/addShopCar", params);
}

// 我的购物车数量
export function myCarNum( userId ) {
  return api.get("/order/shopCarNum", {
    params:{
      userId:userId
    }
  });
}

// 删除购物车
export function deleteCar( id ) {
  return api.get("/order/deleteShopCar", {
    params:{
      id:id
    }
  });
}

// 购物车列表
export function shopCarList( userId ) {
  return api.get("/order/shopCarList", {
    params:{
      userId:userId
    }
  });

}
// 购买
export function wxPay( params ) {
  return api.post("/order/wxPay",params);
}

// 订单详情
export function orderInfo( params ) {
  return api.post("/order/orderInfo",params);
}

// 购物车商品数量增减
export function addOrRedu( params ) {
  return api.post("/order/addOrRedu",params);

}

// 可以使用的优惠券
export function useCoupons( params ) {
  return api.post("/order/useCoupons",params);
}

// 检查商品库存
export function checkStock( params ) {
  return api.post("/order/checkStock",params);
}
